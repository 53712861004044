import { AuthTemplate } from '@/common/templates/auth-template';
import { LoginTemplate } from '@/modules/auth/templates/login-template';
import { GetServerSideProps } from 'next';
import { GetServerSidePropsContext } from 'next/types';
import { httpLoadWhitelabelThemeByDomain } from '@/modules/whitelabel-theme/api/load-whitelabel-theme-by-domain/http-load-whitelabel-theme-by-domain';

export default function Index() {
  return (
    <AuthTemplate>
      <LoginTemplate />
    </AuthTemplate>
  );
}

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const whitelabelTheme = await httpLoadWhitelabelThemeByDomain({
    domain: context.req.headers.host!,
  });

  return {
    props: {
      whitelabelTheme,
    },
  };
};
